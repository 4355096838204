import React, {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useResponsiveDimensions} from "../../lib/hooks/useResponsiveDimensions";
import LanguageDropdown from "../../components/shared/LanguageDropdown/LanguageDropdown";
import peakJobsLogo from "../../assets/svg/peak-jobs-logo.svg";

import "./ApplicationBase.scss";
import {apiCallWithoutAuth} from "../../api/Api";
import CV from "../../components/shared/CV/CV";

const ApplicationBase = () => {
  const {t} = useTranslation("common");
  const navigate = useNavigate();
  const isMobile = useResponsiveDimensions().isMobile;
  const {publicId} = useParams();
  const [application, setApplication] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  let selectedLanguage = localStorage.getItem("lang");

  useEffect(() => {
    if (publicId) {
      apiCallWithoutAuth("GET", `/b2b/applications/public/${publicId}`)
        .then((res) => {
          // The important part is `res.data` should match IApplication shape:
          setApplication(res.data);
          setLoading(false);
        })
        .catch((err) => {
          window.location.href = "https://peakjobs.app";
          console.error(err);
          setLoading(false);
        });
    }
  }, [publicId]);

  return (
    <div className="ApplicationBase">
      <div className="ApplicationBase__left-top">
        <div className="ApplicationBase__logo">
          <img alt="pic" src={peakJobsLogo} className="ApplicationBase__logo-icon"/>
          <LanguageDropdown/>
        </div>
        {application?.job && (
          <p className="ApplicationBase__applied-job-card">
            {t("applicantAppliedOnThisJob")}
            {selectedLanguage === "SQ"
              ? application.job.job_title.title?.sq
              : application.job.job_title.title?.en}
            {t("in")} {application.job.organization_unit.name} - {application.job.organization_unit.city}
          </p>
        )}
        <div className="ApplicationBase__form">
          {application && (
            <CV
              person={application}
              jobId={application.job.id}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicationBase;
